import { EventEmitter, Inject, Injectable, PLATFORM_ID } from "@angular/core";



import { isPlatformBrowser } from "@angular/common";


import { Cities, City } from "../data/city/city";
@Injectable({
    providedIn: 'root'
})

export class CityCookieService {
    public getCookies$: EventEmitter<City>;
    public loaded$: EventEmitter<Number>;
    dbName: string = 'city';
    isBrowser;
    constructor(private cityService: CityService, @Inject(PLATFORM_ID) private platformId: any) {
        this.loaded$ = new EventEmitter();
        this.isBrowser = isPlatformBrowser(platformId);
        this.getCookies$ = new EventEmitter();
        if (this.isBrowser) {
            cityService.needChangeCookie$.subscribe(item => {
                this.changeCookie(item)
                //console.log("need change cookie")
                });

            let openRequest = indexedDB.open(this.dbName);
            openRequest.onupgradeneeded = function () {
                let db = openRequest.result;
                if (!db.objectStoreNames.contains('currentCity')) {
                    db.createObjectStore('currentCity', { keyPath: 'id' });
                }
            };
        }
    }
    changeCookie(city: City): void {

        if (this.isBrowser) {
            let openRequest = indexedDB.open(this.dbName);
            openRequest.onsuccess = () => {
                let db = openRequest.result;
                if (db.objectStoreNames.contains('currentCity')) {
                    let transaction = db.transaction('currentCity', "readwrite");
                    let goods = transaction.objectStore("currentCity");
                    goods.clear();
                    goods.add({ body: City.toJson(city), id: 1 });
                    this.cityService.loaded$.emit(true);
                    this.cityService.cityIsSelected$.emit(true);
                }

            }
        }
    }
    getCookies(): boolean {
        let contains: boolean = false;
        if (this.isBrowser) {
            let openRequest = indexedDB.open(this.dbName);
            let value = '';
            openRequest.onupgradeneeded = () => {
                let db = openRequest.result;
                if (!db.objectStoreNames.contains('currentCity')) {
                    db.createObjectStore('currentCity', { keyPath: 'id' });
                    this.loaded$.emit(0);
                    this.cityService.loaded$.emit(false);
                }
            };
            openRequest.onsuccess = () => {
                let db = openRequest.result;
                const request = db.transaction('currentCity', "readwrite").objectStore('currentCity').get(1);
                request.onsuccess = () => {
                    if (request.result) {
                        value = request.result.body;
                        if (value) {
                            this.cityService.create(City.fromJson(JSON.parse(value)));
                            this.cityService.isLoaded = true;
                            this.cityService.loaded$.emit(true);
                            this.loaded$.emit(2);
                            this.cityService.cityIsSelected$.emit(true);
                        } else {
                            this.loaded$.emit(1);
                            this.cityService.loaded$.emit(true);
                            this.cityService.cityIsSelected$.emit(false);
                        }
                    } else {
                        this.loaded$.emit(1);
                        this.cityService.loaded$.emit(true);
                        this.cityService.cityIsSelected$.emit(false);
                    }
                }
            }
        }
        return contains;
    }
}


let _cities = new Cities([
    new City(
        "8 (800) 551-09-34",
        "ул. Коминтерна, 39Б",
        "127238",
        "Нижний Новгород",
        "Пн-Пт: <b>9:00 - 19:00</b><br>Сб-Вс: <b>9:00 - 18:00</b>",
        "",
        "rvdservis152@mail.ru"),
    new City(
        "8 (800) 551-09-34",
        "Проспект Ленина 1А, к16",
        "606015",
        "Дзержинск",
        "Пн-Пт: <b>9:00 - 17:00</b>",
        "Сб-Вс: <b>Выходные</b>",
        "rvdservis152@mail.ru"),
])
@Injectable({
    providedIn: 'root'
})

export class CityService {
    public cities: Cities = _cities;
    public currentCity: City = this.cities.get(0);
    public cityChanged$: EventEmitter<City>;
    public loaded$: EventEmitter<boolean>;
    public cityIsSelected$: EventEmitter<boolean>;
    public isLoaded = false;
    public needChangeCookie$: EventEmitter<City>;
    constructor() {
        this.cityChanged$ = new EventEmitter();
        this.loaded$ = new EventEmitter();
        this.cityIsSelected$ = new EventEmitter();
        this.needChangeCookie$ = new EventEmitter();
    }
    change(item: number) {
        this.currentCity = this.cities.get(item);
        this.cityChanged$.emit(this.currentCity);
        this.needChangeCookie$.emit(this.currentCity);
    }
    changeByName(name: string, address: string) {

        for (let city of _cities.cities) {
            if (city.city == name && city.address == address) {

                this.currentCity = city;
                this.cityChanged$.emit(this.currentCity);

                this.needChangeCookie$.emit(this.currentCity);
            }
        }
    }
    public create(city: City) {
        this.currentCity = city;
        this.cityChanged$.emit(city);
    }
    public get(): Cities {
        return this.cities;
    }
    public getNames(): string[] {
        return this.cities.names;
    }
    public getStreets(): string[] {
        return this.cities.streets;
    }
    public getCurrent() {
        return this.currentCity;
    }
}
