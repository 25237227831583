import { Injectable } from "@angular/core";
import {
	HttpClient,
	HttpHeaders,
} from '@angular/common/http';
import { delay, Observable, of, ReplaySubject } from "rxjs";
import { CityService } from "./city.service";
let httpOptions: { headers: HttpHeaders };
httpOptions = {
	headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
	providedIn: 'root'
})

export class sendMailService {
	//private url = "https://rvd52.ru/api/mail.php";
	url = "api/mail";
	constructor(private http: HttpClient, private cityService: CityService) {
	}
	shopMessage(data: { phone: string, cart: string }): Observable<any> {
		let command = "shopMessage";
		let newData = { phone: data.phone, city: this.cityService.currentCity.city, cart: data.cart };
		//return of({ STATUS: "ERROR" }).pipe(delay(3000)); // Указывает задержку в миллисекундах (здесь 3 секунды)

		return this.http.post(this.url, {
			'command': command, Headers,
			data: newData,
		}, httpOptions);
	}
	requestMessage(data: { phone: string }): Observable<any> {
		let command = "requestMessage";
		let newData = { phone: data.phone, city: this.cityService.currentCity.city };
		//return of({ STATUS: "ERROR" }).pipe(delay(3000)); // Указывает задержку в миллисекундах (здесь 3 секунды)
		return this.http.post(this.url,
			{
				'command': command, Headers,
				data: newData,
			},
			httpOptions);

	}
	anotherMessage(data: { phone: string, props: string[] }): Observable<any> {
		let command = "anotherMessage";
		let newData = { phone: data.phone, city: this.cityService.currentCity.city, props: data.props };
		//return of({ STATUS: "ERROR" }).pipe(delay(3000)); // Указывает задержку в миллисекундах (здесь 3 секунды)
		return this.http.post(this.url,
			{
				'command': command, Headers,
				data: newData,
			},
			httpOptions);

	}

}
