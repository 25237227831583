

export class Cities {
    count: number = 0;
    cities: City[] = [];
    constructor(cities?: City[]) {
        if (cities) {
            this.count = cities.length;
            this.cities = cities;
        }
    }
    get names() {
        let temp: Array<string> = [];
        for (let city of this.cities) {
            temp.push(city.city);
        }
        return temp;
    }
    get streets() {
        let temp: Array<string> = [];
        for (let city of this.cities) {
            temp.push(city.address);
        }
        return temp;
    }
    static fromJson(json: any) {
        let result = [];
        for (let city of json.cities)
            result.push(City.fromJson(city));
        return new Cities(result)
    }
    static toJson(item: Cities) {
        let tempStr = '';
        for (let city of item.cities) {
            tempStr += City.toJson(city) + ', ';
        }
        tempStr = tempStr.slice(0, -2);
        return `{"count": ${item.count}, "cities":[${tempStr}]}`
    }
    get(id: number) {
        if (id < this.count) {
            return this.cities[id];
        } else {
            return new City();
        }
    }
}

export class City {
    phone: string = "8 (800) 551-09-34";
    address: string = "ул. Коминтерна, 39Б";
    city: string = "Нижний Новгород";
    time: string = "Пн-Пт: <b>9:00 - 19:00</b><br>Сб-Вс: <b>9:00 - 18:00</b>";
    subtime: string = "";
    email: string = "rvdservis152@mail.ru";
    index: string = "127238";
    formattedPhone = "";
    static fromJson(json: any) {
        return new City(json.phone, json.address, json.index, json.city, json.time, json.subtime, json.email);
    }
    constructor(phone?: string, address?: string, index?: string, city?: string, time?: string, subtime?: string, email?: string,) {
        if (phone && address && city && time && (subtime || subtime == "") && index) {
            this.index = index;
            this.phone = phone;
            this.address = address;
            this.city = city;
            this.time = time;
            this.subtime = subtime;
            this.formattedPhone = this.phone.replace(/[^0-9]/g, "");
        }
        if (email) {
            this.email = email;
        }

    }
    static toJson(city: City) {
        return `{"phone": "${city.phone}", "address": "${city.address}", "index": "${city.index}", "city": "${city.city}", "time": "${city.time}", "subtime": "${city.subtime}", "email": "${city.email}" }`
    }
}
